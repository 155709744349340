'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = mergeable;
function mergeable(reducer) {
  reducer.original = reducer;
  reducer.merge = function merge(extra) {
    var original = this.original;
    return function wrapper(state, action) {
      var newState = extra(state, action);
      if (typeof state === 'undefined') {
        // Merge initial state
        var initial = original(undefined, { type: undefined });
        return _extends({}, initial, newState);
      } else {
        return original(newState, action);
      }
    };
  };
  return reducer;
}