'use strict';

var _VirtualizedSelect = require('./VirtualizedSelect');

var _VirtualizedSelect2 = _interopRequireDefault(_VirtualizedSelect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Backwards compatible hack for require('...').default
// See bvaughn/react-virtualized-select/issues/57
_VirtualizedSelect2.default.default = _VirtualizedSelect2.default;


module.exports = _VirtualizedSelect2.default;