'use strict';

exports.__esModule = true;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _password_input = require('../../ui/input/password_input');

var _password_input2 = _interopRequireDefault(_password_input);

var _index = require('../index');

var c = _interopRequireWildcard(_index);

var _index2 = require('../../store/index');

var _index3 = require('../../core/index');

var l = _interopRequireWildcard(_index3);

var _password = require('../password');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PasswordPane = function (_React$Component) {
  _inherits(PasswordPane, _React$Component);

  function PasswordPane() {
    var _temp, _this, _ret;

    _classCallCheck(this, PasswordPane);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.handleChange = function (e) {
      var _this$props = _this.props,
          lock = _this$props.lock,
          policy = _this$props.policy;

      (0, _index2.swap)(_index2.updateEntity, 'lock', l.id(lock), _password.setPassword, e.target.value, policy);
    }, _this.handleShowPasswordChange = function (e) {
      var lock = _this.props.lock;

      (0, _index2.swap)(_index2.updateEntity, 'lock', l.id(lock), _password.setShowPassword, e.target.checked);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  PasswordPane.prototype.render = function render() {
    var _props = this.props,
        i18n = _props.i18n,
        lock = _props.lock,
        placeholder = _props.placeholder,
        policy = _props.policy,
        strengthMessages = _props.strengthMessages,
        hidden = _props.hidden;

    var hiddenCss = hidden ? ' auth0-lock-hidden' : '';
    return _react2.default.createElement(
      'div',
      { className: 'auth0-lock-input-block auth0-lock-input-show-password' + hiddenCss },
      _react2.default.createElement(_password_input2.default, {
        value: c.getFieldValue(lock, 'password'),
        invalidHint: i18n.str('blankErrorHint'),
        showPasswordStrengthMessage: !c.isFieldValid(lock, 'password'),
        isValid: !c.isFieldVisiblyInvalid(lock, 'password'),
        onChange: this.handleChange,
        placeholder: placeholder,
        strengthMessages: strengthMessages,
        disabled: l.submitting(lock),
        policy: policy,
        showPassword: c.getFieldValue(lock, 'showPassword', false),
        lock: lock
      }),
      l.ui.allowShowPassword(lock) && _react2.default.createElement(
        'div',
        { className: 'auth0-lock-show-password' },
        _react2.default.createElement('input', { type: 'checkbox', id: 'slideOne', onChange: this.handleShowPasswordChange }),
        _react2.default.createElement('label', { htmlFor: 'slideOne', title: i18n.str('showPassword') })
      )
    );
  };

  return PasswordPane;
}(_react2.default.Component);

exports.default = PasswordPane;


PasswordPane.propTypes = {
  i18n: _propTypes2.default.object.isRequired,
  lock: _propTypes2.default.object.isRequired,
  onChange: _propTypes2.default.func,
  placeholder: _propTypes2.default.string.isRequired,
  policy: _propTypes2.default.object,
  strengthMessages: _propTypes2.default.object,
  hidden: _propTypes2.default.bool
};
